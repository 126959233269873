import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Link from 'next/link'
import Stack from '../Stack/Stack'
import useStyles from './styles'
import { SPACING } from 'lib/theme'

type CardListItemProps = {
  href?: string
  justifyContent?: string
  display?: string
  children: ReactNode
  handleClick?: () => void
}

const CardListItems = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()
  return (
    <Stack elementClassName={classes.cardListItemElement}>{children}</Stack>
  )
}

export const CardListItem = ({
  href,
  justifyContent,
  children,
  display,
  handleClick = () => null,
}: CardListItemProps) => {
  const classes = useStyles()
  return href ? (
    <Link href={href} legacyBehavior>
      <a className={classes.cardListItemLink}>
        <Box
          p={SPACING.XXS}
          display={display || 'flex'}
          justifyContent={justifyContent || 'flex-start'}
          alignItems="center"
          onClick={handleClick}
        >
          {children}
        </Box>
      </a>
    </Link>
  ) : (
    <Box
      p={SPACING.XXS}
      display="flex"
      justifyContent={justifyContent || 'flex-start'}
      alignItems="center"
    >
      {children}
    </Box>
  )
}

export default CardListItems
