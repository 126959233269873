/* eslint-disable no-magic-numbers */
import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  statusItem: {
    paddingTop: theme.spacing(SPACING.XXS),
    paddingBottom: theme.spacing(SPACING.XXS),
    paddingLeft: theme.spacing(SPACING.XXS),
    paddingRight: theme.spacing(SPACING.XXS),
    borderBottom: '1px solid #E0E0E0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  statusIcon: {
    color: 'rgb(181, 183, 184)',
  },
  statusLabel: {
    fontSize: '12px',
  },
  statusCount: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(SPACING.XXS),
    marginRight: theme.spacing(SPACING.XXS),
  },
  title: {
    fontSize: '14px',
    lineHeight: 1.2,
    fontWeight: 'bold',
    marginBottom: theme.spacing(SPACING.XXS),
  },
  noPostsTitle: {
    lineHeight: 1.5,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))
