import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import useEmblaCarousel from 'embla-carousel-react'
import React from 'react'
import Card from 'components/Card/Card'
import useNavigation from 'components/CardCarousel/useNavigation'
import { Card as CardType } from 'lib/types/card'

import useStyles from './styles'

const CardCarousel = ({
  items,
  cardOptions,
  slidesPerView = 4,
  spaceBetweenSlides = 3,
  loop = false,
  dragFree = true,
}: {
  items: CardType[]
  cardOptions?: Pick<CardType, 'maxHeight' | 'minHeight'> & {
    optimiseImage?: boolean
    prioritiseImage?: boolean
  }
  slidesPerView?: number
  spaceBetweenSlides?: number
  loop?: boolean
  dragFree?: boolean
}) => {
  const styles = useStyles()
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    dragFree,
    containScroll: 'trimSnaps',
    loop,
  })
  const { onPreviousClick, previousDisabled, onNextClick, nextDisabled } =
    useNavigation(emblaApi)

  return (
    <Box className={styles.wrapper} data-test-id="card-carousel">
      {previousDisabled ? null : (
        <IconButton
          data-test-id="card-carousel-previous-button"
          color="primary"
          className={styles.navigationPrevious}
          onClick={onPreviousClick}
          component="span"
          aria-label="previous"
          size="small"
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}

      <Box ref={emblaRef} className={styles.viewport}>
        <Grid container wrap="nowrap" spacing={spaceBetweenSlides}>
          {items.map((item: CardType, index) => (
            <Grid
              item
              flex={`0 0 ${100 / slidesPerView}%`}
              key={item.id}
              data-test-id={`card-carousel-slide-${index}`}
            >
              <Card
                key={item.id}
                {...item}
                {...cardOptions}
                image={{
                  ...item.image,
                  optimise: cardOptions?.optimiseImage,
                  prioritise: cardOptions?.prioritiseImage,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {nextDisabled ? null : (
        <IconButton
          data-test-id="card-carousel-next-button"
          color="primary"
          className={styles.navigationNext}
          onClick={onNextClick}
          component="span"
          aria-label="next"
          size="small"
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default CardCarousel
