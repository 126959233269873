import { alpha } from '@mui/material'
import { CSSProperties, DefaultTheme, makeStyles } from '@mui/styles'

const navigationArrow = (
  theme: DefaultTheme,
  direction: 'previous' | 'next'
): CSSProperties => ({
  top: '50%',
  transform: 'translateY(-50%) scale(2)',
  position: 'absolute',
  zIndex: 2,
  [direction === 'previous' ? 'left' : 'right']: theme.spacing(-6),

  [theme.breakpoints.down('md')]: {
    [direction === 'previous' ? 'left' : 'right']: theme.spacing(-2.5),
    transform: 'translateY(-50%) scale(1.5)',
    '&, &:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
})

const useStyles = makeStyles(theme => ({
  viewport: {
    padding: 3,
    margin: -3,
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
  },
  navigationPrevious: navigationArrow(theme, 'previous'),
  navigationNext: navigationArrow(theme, 'next'),
}))

export default useStyles
