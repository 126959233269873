import { EmblaCarouselType } from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'

// Appropriated from the official Embla generator https://www.embla-carousel.com/examples/generator/
const useNavigation = (emblaApi: EmblaCarouselType | undefined) => {
  const [previousDisabled, setPreviousDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  const onPreviousClick = useCallback(() => {
    emblaApi?.scrollPrev()
  }, [emblaApi])

  const onNextClick = useCallback(() => {
    emblaApi?.scrollNext()
  }, [emblaApi])

  const onCarouselUpdated = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setPreviousDisabled(!emblaApi?.canScrollPrev())
      setNextDisabled(!emblaApi?.canScrollNext())
    },
    [emblaApi]
  )

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onCarouselUpdated(emblaApi)

    // Called when the carousel re-initialises from window resizing
    emblaApi.on('reInit', () => onCarouselUpdated(emblaApi))

    // Called when the active item changes
    emblaApi.on('select', () => onCarouselUpdated(emblaApi))
  }, [emblaApi, onCarouselUpdated])

  return {
    previousDisabled,
    nextDisabled,
    onPreviousClick,
    onNextClick,
  }
}

export default useNavigation
