import { gql } from '@apollo/client'

const standaloneArticleFragment = gql`
  fragment StandaloneArticle on Article {
    sys {
      id
    }
    thumbnailImageSet {
      masterImage {
        url
        width
        height
      }
    }
    heroImageSet {
      masterImage {
        url
        width
        height
      }
    }
    cardKicker
    cardTitle
    title
    cardBody
    cardColour
  }
`

const consolidatedArticleFragment = gql`
  ${standaloneArticleFragment}
  fragment ConsolidatedArticle on Article {
    ...StandaloneArticle
    cardCta {
      label
      address
    }
  }`

export const DASHBOARD_ARTICLES = gql`
    ${standaloneArticleFragment}
    query DashboardArticles($itemLimit: Int) {
        articleCollection(limit: $itemLimit, order: sys_publishedAt_DESC) {
            items {
                ...StandaloneArticle
            }
        }
    }
`

export const DASHBOARD_CONSOLIDATED_ARTICLES = gql`
  ${consolidatedArticleFragment}
  query DashboardArticles($itemLimit: Int) {
    articleCollection(limit: $itemLimit, order: sys_publishedAt_DESC) {
      items {
        ...ConsolidatedArticle
      }
    }
  }
`

export const DASHBOARD_WMH_SECTION = gql`
  ${consolidatedArticleFragment}
  query CuratedContentSection($itemLimit: Int) {
    curatedContentSectionCollection(
      limit: 1,
      where: { type: "Dashboard - WMH" }
    ) {
      items {
        sys {
          id
        }
        heading
        wmhItemsCollection(limit: $itemLimit) {
          items {
            ...ConsolidatedArticle
          }
        }
      }
    }
  }
`

export const DASHBOARD_PREVIEW_CARDS = gql`
  query DashboardPreviewCards {
    previewCardCollection(limit: 8, order: sys_publishedAt_DESC) {
      items {
        sys {
          id
        }
        thumbnailImageSet {
          masterImage {
            url
            width
            height
          }
        }
        kicker
        title
        body
        callToAction {
          label
          address
        }
        colour
      }
    }
  }
`
export const DASHBOARD_CAMPAIGN_CALENDAR = gql`
  query DashboardCampaignCalendar($currentDate: DateTime!, $limit: Int, $skip: Int) {
    campaignCollection(
      where: { endDate_gte: $currentDate },
      order: startDate_ASC,
      limit: $limit,
      skip: $skip
    ) {
      items{
        sys {
          id
        }
        title
        topicId
        endDate
        startDate
        submittedBy
        thumbnailImageSet{
          masterImage{
            fileName
            src: url
          }
        }
        heroImageSet{
          masterImage{
            fileName
            src: url
          }
        }
      }
      total
      skip
    }
  }
`
