import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  headingContainer: {
    width: `100%`,
    maxWidth: `${theme.breakpoints?.values?.sm}px`,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingBottom: theme.spacing(SPACING.S),
  },
  sticky: {
    position: 'sticky',
    top: 10,
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    height: 0,
    padding: 0,
    zIndex: 99,
  },
}))
