import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'
// Needed to override top spacing of a grid item
const OFFSET_PADDING = 2.5
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    marginTop: theme.spacing(-SPACING.XS),
  },
  grid: {
    marginTop: theme.spacing(-SPACING.XS),
  },
  addCardPaddTop: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(OFFSET_PADDING),
    },
  },
}))

export default useStyles
