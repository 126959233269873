import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { fetcher } from 'lib/api'
import { FEAT_CONSOLIDATED_ARTICLES, FEAT_DIGITAL_NOTICEBOARD_CURATED_ARTICLES, SWR_CONFIG } from 'lib/config'
import { DashboardSections } from 'lib/types/dashboard'
import { formatDashboardCard } from 'lib/utils'
import { DASHBOARD_ARTICLES, DASHBOARD_CONSOLIDATED_ARTICLES, DASHBOARD_PREVIEW_CARDS } from 'queries/dashboard'

const CARDS_PER_PAGE = 8

const useDashboardSections = () => {
  const [retrieveSections, setRetrieveSections] = useState(false)
  const [initialised, setInitialised] = useState(false)

  // Articles
  const [
    getArticles,
    {
      loading: articlesLoading,
      error: articlesError,
      data: articlesData,
    },
  ] = useLazyQuery(
    FEAT_CONSOLIDATED_ARTICLES ? DASHBOARD_CONSOLIDATED_ARTICLES : DASHBOARD_ARTICLES,
    { variables: { itemLimit: CARDS_PER_PAGE } },
  )

  // Preview Cards
  const [
    getPreviewCards,
    {
      loading: previewCardsLoading,
      error: previewCardsError,
      data: previewCardsData,
    },
  ] = useLazyQuery(DASHBOARD_PREVIEW_CARDS)

  // WMH and Feature Row Curated Content Section
  const { data: sectionsResponse, isValidating: sectionsLoading, error: sectionsError } = useSWR(
    retrieveSections ? `dashboard/sections` : null,
    url => fetcher<DashboardSections>(url, true),
    SWR_CONFIG,
  )

  // We're conditionally calling the query functions depending on which FF is enabled
  useEffect(() => {
    if (FEAT_DIGITAL_NOTICEBOARD_CURATED_ARTICLES) {
      // Retrieve using the new content type
      setRetrieveSections(true)
    } else if (FEAT_CONSOLIDATED_ARTICLES) {
      // Retrieve consolidated articles
      getArticles()
    } else {
      // Retrieve both articles and preview cards
      getArticles()
      getPreviewCards()
    }

    setInitialised(true)
  }, [])

  const cardsLoading = !initialised || articlesLoading || previewCardsLoading || sectionsLoading
  const cardsError = articlesError || previewCardsError || sectionsError

  const {
    wmhSection,
    featureRowSection,
  } = sectionsResponse?.data || {}

  const featureRowSectionCards: any[] = [...(featureRowSection?.wmhItems || []), ...(featureRowSection?.corporateSiteItems || [])]

  const wmhSectionCards = [
    ...wmhSection?.wmhItems || [],
    ...wmhSection?.corporateSiteItems || [],
    ...articlesData?.articleCollection?.items || [],
    ...previewCardsData?.previewCardCollection?.items || [],
  ].slice(0, CARDS_PER_PAGE)

  return {
    loading: cardsLoading,
    error: cardsError,
    wmhSection: {
      heading: wmhSection?.heading || 'Explore the Westfield Marketing Hub',
      cards: wmhSectionCards.map(formatDashboardCard)
    },
    featureRowSection: {
      heading: featureRowSection?.heading,
      cards: featureRowSectionCards.map(formatDashboardCard)
    }
  }
}

export default useDashboardSections