import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import Box from '@mui/material/Box'
import useStyles from './styles'
import BaseCard from 'components/BaseCard/BaseCard'
import CardContent from 'components/BaseCard/CardContent'
import CardKicker from 'components/BaseCard/CardKicker'
import CardTitle from 'components/BaseCard/CardTitle'
import CardMedia from 'components/BaseCard/CardMedia'
import CardCTA from 'components/BaseCard/CardCta'
import CardListItems, {
  CardListItem,
} from 'components/CardListItems/CardListItems'
import Stack from 'components/Stack/Stack'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { AFeaturePage, AFeature, ColourScheme } from 'lib/types/card'
import { returnURLWithHttps } from 'lib/utils'
import { formatDate, getCurrentDate } from 'lib/dateUtils'
import { DateTimeFormat } from 'lib/types/dateUtils'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import { useQuery } from '@apollo/client'
import { DASHBOARD_CAMPAIGN_CALENDAR } from 'queries/dashboard'
import { ApolloError } from '@apollo/client'
import { isEmpty, head, get } from 'lodash'

const DEFAULT_CAMPAIGN = {
  kicker: 'Marketing opportunities',
  title: 'Upcoming Westfield campaigns',
  ctaLabel: 'View campaign opportunities',
  ctaUrl: '/marketing-hub/campaigns/calendar',
  image: {
    src: '',
    alt: '',
    width: 366,
    height: 366,
  },
  pages: [],
}

const MAXIMUM_AFEATURED_CAMPAIGNS = 3

// TODO: In future we should move towards proper errors rather than booleans.
type FeaturedCampaignsProps = {
  error: ApolloError | undefined
  campaignsData: AFeature
  isLoading: boolean
}

const FeaturedCampaigns = ({
  error,
  campaignsData,
  isLoading,
}: FeaturedCampaignsProps) => {
  const classes = useStyles({ colourScheme: ColourScheme.Light })

  if (error) {
    return null
  }

  if (isLoading) {
    return <LoadingContainer center />
  }

  return (
    <>
      {!isEmpty(campaignsData.pages) ? (
        <span data-test-id="featured-campaign-entry">
          {campaignsData.pages.map((page, index) => (
            <TrackBox id={`campaign-calendar[${index}]`} key={page.id}>
              <CardListItem href={page.url} display="block">
                <Typography color="primary" className={classes.pageTitle}>
                  {page.title}
                </Typography>
                <Typography color="primary" variant="caption">
                  <time>{formatDate(page.date, DateTimeFormat.FULLSHORT)}</time>
                  {dayjs(page.endDate).isAfter(dayjs(page.date)) && (
                    <>
                      {' - '}
                      {formatDate(page.endDate, DateTimeFormat.FULLSHORT)}
                    </>
                  )}
                </Typography>
              </CardListItem>
            </TrackBox>
          ))}
        </span>
      ) : (
        <Typography
          color="primary"
          variant="body1"
          data-test-id="featured-campaign-empty"
        >
          There are no scheduled campaigns at this time.
        </Typography>
      )}
    </>
  )
}

export default function CampaignCalendarCard() {
  const {
    loading: isLoading,
    error,
    data: campaignsWithMetadata,
  } = useQuery(DASHBOARD_CAMPAIGN_CALENDAR, {
    variables: {
      currentDate: getCurrentDate(),
      limit: MAXIMUM_AFEATURED_CAMPAIGNS,
    },
  })

  const [campaignsData, setCampaignsData] = useState<AFeature>(DEFAULT_CAMPAIGN)
  useEffect(() => {
    if (campaignsWithMetadata) {
      const aFeaturedCampaigns: AFeaturePage[] = []

      if (aFeaturedCampaigns) {
        for (const campaign of campaignsWithMetadata.campaignCollection.items) {
          const image =
            campaign.thumbnailImageSet?.masterImage ||
            campaign.heroImageSet?.masterImage

          aFeaturedCampaigns.push({
            url: `/marketing-hub/campaigns/calendar/${campaign.sys.id}`,
            id: campaign.sys.id,
            title: campaign.title,
            date: campaign.startDate,
            endDate: campaign.endDate,
            image: {
              url: returnURLWithHttps(image.src),
              alt: image.fileName,
            },
          })
        }
        let firstCampaign = head(aFeaturedCampaigns)

        setCampaignsData(() => ({
          ...DEFAULT_CAMPAIGN,
          pages: aFeaturedCampaigns.slice(0, MAXIMUM_AFEATURED_CAMPAIGNS),
          image: {
            ...DEFAULT_CAMPAIGN.image,
            src: get(firstCampaign, 'image.url', ''),
            alt: get(firstCampaign, 'image.alt', ''),
          },
        }))
      }
    }
  }, [campaignsWithMetadata])

  return (
    <BaseCard data-test-id="a-feature-card">
      <TrackBoxGroup
        id="campaign-calendar-card"
        display="flex"
        flexDirection="row-reverse"
      >
        <CardMedia
          data-test-id="img-a-feature"
          src={campaignsData.image.src}
          alt={campaignsData.image.alt}
          width={campaignsData.image.width}
          height={campaignsData.image.height}
        />
        <Box display="flex" flexDirection="column" flex={1}>
          <CardContent data-test-id={`cardcontent-a-feature-card`}>
            <Stack spacing={2}>
              <CardKicker
                text={campaignsData.kicker}
                data-test-id={`kicker-${campaignsData.kicker}`}
              />
              <TrackBox id="campaign-calendar-header">
                {campaignsData.pages.length > 0 ? (
                  <Link href={campaignsData.ctaUrl} legacyBehavior>
                    <a>
                      <CardTitle
                        variant="h5"
                        data-test-id={`title-${campaignsData.kicker}`}
                        text={campaignsData.title}
                      />
                    </a>
                  </Link>
                ) : (
                  <CardTitle
                    variant="h5"
                    data-test-id={`title-${campaignsData.kicker}`}
                    text={campaignsData.title}
                  />
                )}
              </TrackBox>
              <CardListItems>
                {isLoading ? (
                  <Box mt={6}>
                    <LoadingContainer />
                  </Box>
                ) : (
                  <FeaturedCampaigns
                    error={error}
                    campaignsData={campaignsData}
                    isLoading={isLoading}
                  />
                )}
              </CardListItems>
            </Stack>
          </CardContent>
          {campaignsData.pages.length > 0 && (
            <TrackBox id="campaign-calendar-button">
              <CardCTA
                data-test-id={`button-a-feature`}
                text={campaignsData.ctaLabel}
                href={campaignsData.ctaUrl}
              />
            </TrackBox>
          )}
        </Box>
      </TrackBoxGroup>
    </BaseCard>
  )
}
