import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  cardListItemElement: {
    borderBottom: '1px solid #E0E0E0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  cardListItemLink: {
    '&:hover > div': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))
