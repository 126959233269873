import { Link } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDialog } from './useDialog'
import { LOCAL_STORAGE_KEYS } from 'lib/config'
import { isAfter } from 'lib/dateUtils'
import dayjs from 'dayjs'

// Temporary dialog for black friday
const BLACK_FRIDAY_EXPIRED_DATE = '2024-11-30'

const useBlackFridayNotification = (startDate: Date) => {
  const { showFeedback } = useDialog()
  const showUpdateNotification = async () => {
    await showFeedback({
      title: `Maximise your brand's exposure this Black Friday!`,
      content: (
        <Typography>
          As a Westfield Marketing Hub user now's the time to get your content
          ready. To get featured across Westfield channels and drive your sales
          during the busiest season of the year, submit your offers{' '}
          <Link
            color="#051df7"
            variant="body2"
            href={'/marketing-hub/campaigns/posts/new'}
            sx={{ textDecoration: 'none' }}
          >
            now
          </Link>
          !
        </Typography>
      ),
    })
  }

  useEffect(() => {
    const hasSeenLoginNotification =
      localStorage.getItem(LOCAL_STORAGE_KEYS.HAS_SEEN_LOGIN_NOTIFICATION) ===
      'true'

    const isExpired = isAfter(startDate, dayjs(BLACK_FRIDAY_EXPIRED_DATE))

    if (!hasSeenLoginNotification && !isExpired) {
      showUpdateNotification()
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.HAS_SEEN_LOGIN_NOTIFICATION,
        'true'
      )
    }
  }, [localStorage, showUpdateNotification, isAfter])
}

export default useBlackFridayNotification
