import React from 'react'
import Typography from '@mui/material/Typography'
import useStyles from './styles'
import { retailerStores } from 'lib/atoms/retailer'
import { useRecoilValue } from 'recoil'
import SubmitContentButton from 'components/SubmitContentButton/SubmitContentButton'

export default function DashboardHeader() {
  const classes = useStyles()
  const retailerStoresDetails = useRecoilValue(retailerStores)
  const retailerHasStores =
    retailerStoresDetails.storeData?.data?.items.length > 0

  return (
    <>
      <div className={classes.sticky}>
        {retailerHasStores && <SubmitContentButton />}
      </div>
      <div className={classes.headingContainer}>
        <Typography color="primary" variant="h6" component="h1">
          Welcome to the Westfield Marketing Hub
        </Typography>
      </div>
    </>
  )
}
