import React from 'react'
import Link from 'next/link'
import AddIcon from '@mui/icons-material/Add'
import Button from 'components/Button/Button'
import TrackBox from 'components/Track/TrackBox'

export default function DashboardHeader({
  id = 'submit-content-button',
  groupId = 'subnavbar',
}: {
  id?: string
  groupId?: string
}) {
  return (
    <TrackBox id={id} groupId={groupId}>
      <Link passHref href="/marketing-hub/campaigns/posts/new" legacyBehavior>
        <Button
          padded
          label="SUBMIT CONTENT"
          data-test-id="submitContentButton"
          shape="round"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          loading={false}
          fullWidth={false}
          size="small"
        />
      </Link>
    </TrackBox>
  )
}
