import React from 'react'
import { cloneDeep } from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import DevicesIcon from '@mui/icons-material/Devices'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import ListAltIcon from '@mui/icons-material/ListAlt'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import InboxIcon from '@mui/icons-material/Inbox'
import CircularProgress from '@mui/material/CircularProgress'
import useStyles from './styles'
import CardListItems, {
  CardListItem,
} from 'components/CardListItems/CardListItems'
import BaseCard from 'components/BaseCard/BaseCard'
import CardContent from 'components/BaseCard/CardContent'
import CardKicker from 'components/BaseCard/CardKicker'
import Stack from 'components/Stack/Stack'
import CardCTA from 'components/BaseCard/CardCta'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'

const LOADING_SPINNER_SIZE = 60
type LifecycleStages =
  | 'Please review'
  | 'Live'
  | 'Scheduled'
  | 'Submitted'
  | 'Draft'

enum LifecycleStagesLabel {
  'Please review' = 'PLEASE REVIEW',
  Live = 'LIVE',
  Scheduled = 'SCHEDULED',
  Submitted = 'SUBMITTED',
  Draft = 'DRAFT',
}

interface Status {
  count: number
  status: LifecycleStages
}

type StatusItemMetadata = {
  [keys in LifecycleStages]: {
    Icon: React.ElementType
    label: LifecycleStagesLabel
    status: LifecycleStages
    badgeColor?: string
    count: number
  }
}

const STATUS_ITEMS_METADATA: StatusItemMetadata = {
  'Please review': {
    Icon: MoveToInboxIcon,
    label: LifecycleStagesLabel['Please review'],
    status: 'Please review',
    badgeColor: '#FF0000',
    count: 0,
  },
  Live: {
    Icon: DevicesIcon,
    label: LifecycleStagesLabel.Live,
    status: 'Live',
    count: 0,
  },
  Scheduled: {
    Icon: CalendarTodayIcon,
    label: LifecycleStagesLabel.Scheduled,
    status: 'Scheduled',
    count: 0,
  },
  Submitted: {
    Icon: InboxIcon,
    label: LifecycleStagesLabel.Submitted,
    status: 'Submitted',
    count: 0,
  },
  Draft: {
    Icon: ListAltIcon,
    label: LifecycleStagesLabel.Draft,
    status: 'Draft',
    count: 0,
  },
}

const mergeStatusItems = (statusItems: Status[]) => {
  const statusObj = cloneDeep(STATUS_ITEMS_METADATA)
  statusItems.forEach(statusItem => {
    if (statusObj[statusItem.status]) {
      statusObj[statusItem.status].count = statusItem.count
    }
  })
  const statuses = Object.values(statusObj).filter(item =>
    item.label === LifecycleStagesLabel['Please review'] ? item.count > 0 : true
  )
  return statuses
}

const StatusItem = ({ status, count }: Status) => {
  const classes = useStyles()
  const statusData = STATUS_ITEMS_METADATA[status]
  const { Icon } = statusData
  const AnyIcon = Icon as any
  return (
    <>
      {statusData.badgeColor && count > 0 ? (
        <Badge color="secondary" variant="dot" overlap="circular">
          <AnyIcon className={classes.statusIcon} />
        </Badge>
      ) : (
        <AnyIcon className={classes.statusIcon} />
      )}
      <Typography color="primary" className={classes.statusLabel}>
        <strong>
          <span className={classes.statusCount}>{count}</span>
        </strong>
        {statusData.label}
      </Typography>
    </>
  )
}

const CARD_TITLE = 'Content status'

const ConditionalContentElements = ({
  loading,
  statusItems,
}: {
  loading: boolean
  statusItems: Status[]
}) => {
  const classes = useStyles()
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <CircularProgress color="secondary" size={LOADING_SPINNER_SIZE} />
      </Box>
    )
  }

  const mergedStatusItems = mergeStatusItems(statusItems)

  if (statusItems.length === 0) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Box px={2}>
          <Typography
            className={classes.noPostsTitle}
            color="primary"
            variant="subtitle1"
            data-test-id="title-Posts overview"
            component="h2"
          >
            Start engaging valuable Westfield customers today
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Stack spacing={2}>
      <CardKicker text={CARD_TITLE} data-test-id="kicker-Posts overview" />
      <CardListItems>
        {mergedStatusItems.map(statusItem => (
          <CardListItem key={statusItem.status}>
            <StatusItem {...statusItem} key={statusItem.status} />
          </CardListItem>
        ))}
      </CardListItems>
    </Stack>
  )
}

const PostsOverview = ({ statusItems }: { statusItems: Status[] }) => {
  const loading = !statusItems
  return (
    <TrackBoxGroup id="post-overview">
      <BaseCard data-test-id="card-Posts overview" cta={{ url: '/posts' }}>
        <CardContent data-test-id="cardcontent-Posts overview">
          <TrackBox id="card-cta">
            <ConditionalContentElements
              loading={loading}
              statusItems={statusItems}
            />
          </TrackBox>
        </CardContent>
        {!loading && (
          <TrackBox id="card-cta">
            <CardCTA
              text={
                statusItems?.length === 0
                  ? 'SUBMIT CONTENT'
                  : 'VIEW ALL CONTENT'
              }
              data-test-id="button-Posts overview"
            />
          </TrackBox>
        )}
      </BaseCard>
    </TrackBoxGroup>
  )
}

export default PostsOverview
