import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ErrorPage from 'next/error'
import React from 'react'
import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import CampaignCalendarCard from 'components/CampaignCalendarCard/CampaignCalendarCard'
import Card from 'components/Card/Card'
import CardCarousel from 'components/CardCarousel/CardCarousel'
import DashboardHeader from 'components/DashboardHeader/DashboardHeader'
import Layout from 'components/Layout/Layout'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import PostsOverview from 'components/PostsOverview/PostsOverview'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import { withCustomAuth } from 'components/WithCustomAuth'
import useBlackFridayNotification from 'hooks/useBlackFridayNotification'
import useDashboardSections from 'hooks/useDashboardSections'
import { fetcher } from 'lib/api'
import { SWR_CONFIG } from 'lib/config'
import { currentRetailerDetailId } from 'lib/selectors/retailer'
import { SPACING } from 'lib/theme'
import { Card as CardType } from 'lib/types/card'
import useStyles from 'styles/dashboardStyle'

const GRID_MARGIN_SPACING = 3
const CARD_HEIGHT = 392

function Dashboard() {
  const classes = useStyles()
  const retailerId = useRecoilValue(currentRetailerDetailId)
  const {
    loading: loadingSections,
    error: sectionsError,
    wmhSection,
    featureRowSection,
  } = useDashboardSections()

  useBlackFridayNotification(new Date())

  const { data: postStatusData } = useSWR(
    retailerId ? `${retailerId}/posts/status` : null,
    url => fetcher(url),
    SWR_CONFIG
  )

  if (loadingSections) {
    return <LoadingContainer center />
  }

  if (sectionsError) {
    return <ErrorPage statusCode={400} />
  }

  return (
    <>
      <DashboardHeader />
      <Layout>
        <Grid container spacing={GRID_MARGIN_SPACING} className={classes.root}>
          <Grid item xs={3}>
            <PostsOverview statusItems={postStatusData?.data} />
          </Grid>
          <Grid item xs={9}>
            <CampaignCalendarCard />
          </Grid>

          {/* Feature Row Section */}
          {featureRowSection?.cards?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Box mt={SPACING.S}>
                  <Typography variant="h6" component="h2">
                    {featureRowSection?.heading}
                  </Typography>
                </Box>
              </Grid>
              <TrackBoxGroup
                id="feature-row-section"
                component={Grid}
                item
                width="100%"
              >
                <CardCarousel
                  items={featureRowSection.cards}
                  spaceBetweenSlides={GRID_MARGIN_SPACING}
                  cardOptions={{
                    maxHeight: CARD_HEIGHT,
                    optimiseImage: true,
                    prioritiseImage: true,
                  }}
                />
              </TrackBoxGroup>
            </>
          ) : null}

          {/* WMH Section */}
          <Grid item xs={12}>
            <Box mt={SPACING.S}>
              <Typography variant="h6" component="h2">
                {wmhSection?.heading}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <TrackBoxGroup
              id="wmh-section"
              component={Grid}
              container
              spacing={GRID_MARGIN_SPACING}
              className={classes.grid}
            >
              {wmhSection.cards.map((item: CardType) => (
                <Grid
                  item
                  xs={3}
                  key={item.id}
                  className={classes.addCardPaddTop}
                >
                  <Card
                    key={item.id}
                    {...item}
                    image={{
                      ...item.image,
                      optimise: true,
                    }}
                    maxHeight={CARD_HEIGHT}
                  />
                </Grid>
              ))}
            </TrackBoxGroup>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}

export default withCustomAuth(Dashboard)
